.formField {
  background-color: #fff;
  padding: 2rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
}

.formField input,
.formField select,
.formField textarea {
  display: block;
  padding: 0.5rem;
  margin: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.formField label{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.labelText{
  min-width: 5.5rem;
  text-align: left;
}

.formField textarea {
  resize: vertical;
}

.error {
  color: red;
  margin-top: 5px;
  font-size: 0.9em;
}

.formField .validations {
  margin-top: 10px;
}

.formField .conditionalLogic {
  margin-top: 15px;
}

.removeBtn {
  background-color: #f44336;
  color: white;
  padding: 1rem 2rem;
  font-weight: bold;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 2rem;
}

.removeBtn:hover {
  background-color: #e53c3c;
}
